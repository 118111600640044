import React from 'react';
import PropTypes from "prop-types";
import {VerifyEmail} from "../VerifyEmail/VerifyEmail";

const FullPageIframe = ({url, title}) => {
  return (
    <iframe src={url+window.location.search} title={title} style={{width: '100%', height: '100vh', border: 'none', display:"block"}} />
  );
};

export default FullPageIframe;

FullPageIframe.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}
