// eslint-disable-next-line
import React, { useCallback, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { ValidateSessionTicket } from "../Login/scripts/ValidateSessionTicket";
import { trackPathForAnalytics } from "../Analytics/TrackPageForAnalytics";

import { Login } from "../Login/Login";
import { ResetPassword } from "../Login/ResetPassword";
import { Test } from "../ContentDeliverySystem/contentBlock/testing";
import { ErrorPage } from "../ContentDeliverySystem/error";
import { DisplayContent } from "../ContentDeliverySystem/Experience/DisplayContent/DisplayContent";
import { UniSSO } from "../Login/UniSSO/UniSSO";
import { UniSSOCode } from "../Login/UniSSO/UniSSOCode";
import { GoogleRedirect } from "../Login/scripts/GoogleSignIn";
import { DisplayName } from "../Login/DisplayName/DisplayName";
import { Verify } from "../Login/Verify/Verify";
import { PaymentSuccess } from "../ContentDeliverySystem/paymentSuccess";
import { firebaseCall } from "../Utils/firebaseAnalytics";
import { RestorePurchase } from "../Login/RestorePurchase/RestorePurchase";
import { Landing } from "../Landing/Landing";
import { Deeplinks } from "../Deeplinks/Deeplinks";
import { DeeplinkRedirect } from "../Blog/DeeplinkRedirect";
import { VerifyEmail } from "../VerifyEmail/VerifyEmail";
import { WebPrivacyPolicy } from "../PrivacyPolicy/WebPrivacyPolicy";
import { TermsOfService } from "../PrivacyPolicy/TermsOfService";
import { AppPrivacyPolicy } from "../PrivacyPolicy/AppPrivacyPolicy";
import { UniSSOApp } from "../Login/UniSSO/UniSSOApp";
import { UniSSOAppCode } from "../Login/UniSSO/UniSSOAppCode";
import { Dentistry } from "../Landing/Dentistry/Dentistry";
import { Aboutus } from "../Landing/Aboutus/Aboutus";
import TagManager from "react-gtm-module";
import { APITest } from "../ApiTest";
import { TikTokTest } from "../Deeplinks/TikTokTest";
import { PrescriptionDisclaimer } from "../PrescriptionDisclaimer/PrescriptionDisclaimer";
import { NursingBrochure } from "../Utils/nursingBrochure";
import SubscriptionUpdated from "../ContentDeliverySystem/subscriptionUpdated";
import FullPageIframe from "../Landing/FullPageIframe";
const tagManagerArgs = {
    gtmId: "G-1VLR1Y7D67",
};
TagManager.initialize(tagManagerArgs);

function App() {
    console.log("React Version: " + React.version);

    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            //title: title,
        },
    });

    ValidateSessionTicket();

    const { pathname, search } = useLocation();
    console.log("pathname", pathname, "search", search);

    const analytics = useCallback(() => {
        trackPathForAnalytics({
            path: pathname,
            title: pathname.split("/")[1],
        });
    }, [pathname, search]);

    useEffect(() => {
        analytics();
    }, [analytics]);
    window.addEventListener("visibilitychange", (event) => {
        event.preventDefault();
        firebaseCall("app_close", {
            session_time: "",
            full_email: localStorage.getItem("contactEmail"),
        });
    });
    return (
        <div>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/ResetPassword" element={<ResetPassword />} />
                {/*path changed to home for testing
                
                <Route
                    path="/*"
                    element={<Blog blogId={pathname.split("/")[1]} />}
                />
                
                */}
                <Route path="/Experience/*" element={<DisplayContent path={"experience"} id={undefined} />} />
                <Route path="/Experience/clean" element={<DisplayContent path={"experience/clean"} id={undefined} />} />
                <Route path="/Experience/:topic/:experience" element={<DisplayContent id={undefined} path={"experience"} />} />
                <Route path="/Premium" element={<DisplayContent id={undefined} path={"premium"} />} />

                <Route path="WordPressRoute" element={<Test />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="/StandardLessons" element={<DisplayContent id={undefined} path={"standardLessons"} />} />
                <Route path="/Profile" element={<DisplayContent path={"profile"} />} />
                <Route path="/Profile/Subscription" element={<DisplayContent path={"profile/subscription"} />} />
                <Route path="/Profile/ResetPassword" element={<DisplayContent path={"profile/resetpassword"} />} />

                <Route path="WordPressRoute" element={<Test />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="/StandardLessons" element={<DisplayContent id={undefined} />} />
                <Route path="/UniSSORedirect" element={<UniSSO />} />
                <Route path="/UniSSORedirect/*" element={<UniSSO />} />
                <Route path="/UniSSORedirectApp" element={<UniSSOApp />} />
                <Route path="/UniSSORedirectApp/*" element={<UniSSOApp />} />
                <Route path="/UniSSORedirectCode" element={<UniSSOCode />} />
                <Route path="/UniSSORedirectCode/*" element={<UniSSOCode />} />
                <Route path="/UniSSORedirectAppCode" element={<UniSSOAppCode />} />
                <Route path="/UniSSORedirectAppCode/*" element={<UniSSOAppCode />} />
                <Route path="/GoogleRedirect" element={<GoogleRedirect />} />
                <Route path="/DisplayName" element={<DisplayName />} />
                <Route path="/Verify/*" element={<Verify />} />
                <Route path="/stripeSuccess" element={<PaymentSuccess />} />
                <Route path={"/subscriptionUpdated"} element={<SubscriptionUpdated />} />
                <Route path="/RestorePurchase" element={<RestorePurchase />} />
                <Route path="/Landing" element={<Landing activeLink={""} />} />
                <Route path="/Landing/Students" element={<Landing activeLink={"Students"} />} />
                <Route path="/Landing/Institutions" element={<Landing activeLink={"Institutions"} />} />
                <Route path="/Landing/Professionals" element={<Landing activeLink={"Professionals"} />} />

                <Route
                    path="/deeplink"
                    element={<Deeplinks deeplinksId={pathname.split("/")[1]} search={search.split("?")[1]} fullSearch={search} />}
                />
                <Route path="/verification-complete" element={<VerifyEmail success={true} />} />
                <Route path="/apiTest" element={<APITest />} />
                <Route path="/verification-failed" element={<VerifyEmail success={false} />} />
                <Route path="/website-privacy-policy" element={<WebPrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/PrescriptionDisclaimer" element={<PrescriptionDisclaimer />} />
                <Route path="/application-privacy-policy" element={<AppPrivacyPolicy />} />
                <Route path="/Nursing-Evaluation-Brochure" element={<NursingBrochure />} />

                <Route path="/dentistry" element={<Dentistry />} />
                <Route path="/aboutus" element={<Aboutus />} />
                <Route path="/tiktok" element={<TikTokTest />} />
                <Route path="/downloads" element={<FullPageIframe url={"https://im-sales-portal-8acc2a83f73b.herokuapp.com/downloads/downloads.html"} title={"Downloads"}/>} />

                <Route path="/*" element={<DeeplinkRedirect deeplinksId={pathname.split("/")[1]} search={search.split("=")[1]} />} />
            </Routes>
        </div>
    );
}

export default App;
