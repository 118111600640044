import React, {useEffect} from "react";
import PropTypes from "prop-types";

import {useSearchParams} from "react-router-dom";

import immersify_logo from "../images/immersify_logo.svg";
import shape_1 from "../images/shape_1.svg";
import loader from "../images/loader3.gif";

//import { getMobileOperatingSystem } from '../Utils/getMobileOperatingSystem';

// https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl[PARAMS_GO_HERE]&apn=com.immersifyeducation.immersifydental&isi=1509735183&ibi=com.immersifyeducation.immersifydental
// https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=immersifyeducation://immersifydental?loadScene=DentalID|sceneParams=[ModelGroupLabel:none,ContentGroupLabel:none,mode:quiz]|activityID=3dDental_prac&apn=com.immersifyeducation.immersifydental&isi=1509735183&ibi=com.immersifyeducation.immersifydental

// https://immersifyeducation.com/deeplink?dl=immersifyeducation://immersifydental?loadScene%3DDentalID%7CsceneParams%3D%5BModelGroupLabel:none,ContentGroupLabel:none,mode:quiz%5D%7CactivityID%3D3dDental_prac

// http://localhost:3000/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DTEST%5D
// http://localhost:3000/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DTESTYT%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental
// http://localhost:3000/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DGATESTING2%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental

function redirectToDeeplink(deeplink) {
  window.location.href = deeplink

  // Wait 5 seconds before giving up and redirecting to the origin

  setTimeout(() => {
    window.location.href = window.location.origin
  }, 5000)

}

export const Deeplinks = (props) => {
  const [searchParams] = useSearchParams();
  let deeplink = searchParams.get("dl") || searchParams.get("link");

  if (!deeplink) {
    console.error("No deeplink found, redirecting to origin");
    deeplink = window.location.origin
  }

  // Remove surrounding [] if they exist
  // Regex just removes the first and last character if they are [ or ]
  deeplink = deeplink.replace(/^\[|]$/g, "");


  console.log("Redirecting to deeplink: ", deeplink);

  // Now a few ways to redirect to the deeplink because some browsers
  // are picky about how they handle deeplinks/js redirects
  window.location.href = deeplink;
  redirectToDeeplink(deeplink);
  useEffect(() => redirectToDeeplink(deeplink), []);


  return (
    <section className="container-fluid">
      <div className="row">
        <div className="col-xl-7 col-md-6 left_block d-none d-md-block">
          <div className="signup_image">
            <div className="shape_block">
              <div className="shape_1">
                <img alt="placeholder" src={shape_1}/>
              </div>
              <div className="shape_2">
                <img alt="placeholder" src={shape_1}/>
              </div>
            </div>
            <div className="login_text_block">
              <h1>LEARN.</h1>
              <h1>EXPERIENCE.</h1>
              <h1>CONNECT.</h1>
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-md-6">
          <div className="logo_wrap">
            <img alt="placeholder" src={immersify_logo}/>
          </div>
          <div className="signup_form_area redirect_sec">
            <div className="loader_dir">
              <img src={loader} alt="loader"/>
            </div>
            <h2>Redirecting...</h2>
            <h3>Please do not refresh </h3>
            <p>
              If you have been waiting longer than a few seconds
              please
              <a href={deeplink}>
                <button
                >
                  Click here
                </button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

Deeplinks.propTypes = {
  deeplinksId: PropTypes.string,
  search: PropTypes.string,
  fullSearch: PropTypes.string
};
