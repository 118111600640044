import React, {useState} from "react";
import education_banner from "../../images/landing/edu_banner.svg";
// import edu_immersify from "../../images/landing/edu_immersify.png";
import Slider from "react-slick";
import {useEffect} from "react";
import classNames from "classnames";
import WOW from "wowjs";
import kyle_tupaz from "../../images/landing/KyleTupaz.png";
import ali_shazib from "../../images/landing/AliShazib.png";
import richard_moore from "../../images/landing/RichardMoore.png";
import amanda_hogan from "../../images/landing/AmandaHogan.jpeg";
import demo_1 from "../../images/landing/demo_1.svg";
import cr1 from "../../images/landing/dentistry/cr_1.png";
import cr2 from "../../images/landing/dentistry/cr_2.png";
import cr3 from "../../images/landing/dentistry/cr_3.png";
import cr4 from "../../images/landing/dentistry/cr_4.png";
import cs_1 from "../../images/landing/cs_1.png";
import cs_2 from "../../images/landing/cs_2.png";
import cs_3 from "../../images/landing/cs_3.png";
import phoenix from "../../images/landing/phoenix.png";
import aberdeen from "../../images/landing/aberdeen.png";
import james_cook from "../../images/landing/partners/james_cook.png";
import pnwu from "../../images/landing/partners/pnwu.png";
import ucla from "../../images/landing/ucla.png";
// import explore_immersify from "../../images/landing/explore_immersify.mp4";
import {firebaseCall} from "../../Utils/firebaseAnalytics";
import brand from "../../images/landing/professionals/brand.svg";
import value from "../../images/landing/professionals/add_value.svg";
import ahead from "../../images/landing/professionals/get_ahead.svg";

export const Educators = () => {
  const [addCSS, setAddCss] = useState(true);

  document.addEventListener("DOMContentLoaded", function () {
    const observer = new IntersectionObserver((entries) => {
      // Loop over the entries
      entries.forEach((entry) => {
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation class
          setAddCss(true);
        }
      });
    });

    observer.observe(document.querySelector(".stats_section"));
  });

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const settings = {
    dots: true, infinite: false, speed: 500, slidesToScroll: 1, arrows: true,

    responsive: [{
      breakpoint: 767, settings: {
        slidesToShow: 1,
      },
    }, {
      breakpoint: 1024, settings: {
        slidesToShow: 2,
      },
    }, {
      breakpoint: 1200, settings: {
        slidesToShow: 3,
      },
    }, {
      breakpoint: 8000, settings: {
        slidesToShow: 3,
      },
    },],
  };

  function handleTryWebPlatform() {
    window.location.href = process.env.REACT_APP_CLIENT_URL + "/premium";
  }

  // const [showIframe, setShowIframe] = useState(true);

  // const handleIframeError = () => {
  //     setShowIframe(false);
  // };

  return (<>
    <section className="container first_banner_sec mt-2 mb-5 wow animate__animated animate__fadeInUp">
      <div className="row">
        <div className="col-lg-6 col-md-12 order-lg-1 order-2">
          <div className="first_banner_txt mt-4">
            <h2 className="landing_main_head">
              Interactive learning, reimagined<span className="end_not"></span>
            </h2>
            <p className="landing_main_para mt-3">
              Immersify provides lecturers and academics with interactive resources spanning from
              beginner to advanced content, for
              a comprehensive learning experience.
            </p>
          </div>
        </div>

        <div className="col-lg-6 col-md-12 order-lg-2 order-1">
          <div className="first_banner_img text-center">
            <img src={education_banner} alt="Student banner"/>
          </div>
        </div>
      </div>
    </section>
    <section className="professionals_wrap mt-5 pt5 wow animate__animated animate__fadeInUp">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="m_head">
              <h2 className="landing_main_head">
                Are you a business in the health or dental
                care sector?
              </h2>
            </div>
          </div>

          <div className="row mt_pro">
            <div className="col-lg-4 col-md-12">
              <div className="prof_q_box">
                <img src={brand} alt="Attract talent"/>
                <h4>Advance Your Brand</h4>
                <p>
                  Grow and reinforce your brand reputation
                  to a highly targeted audience through
                  collaboration opportunities.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="prof_q_box">
                <img src={value} alt="Attract talent"/>
                <h4>Add value</h4>
                <p>
                  Create value beyond your product through
                  unique channels and continue to inspire,
                  educate, and entertain your audience.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="prof_q_box">
                <img src={ahead} alt="Attract talent"/>
                <h4>Get Ahead</h4>
                <p>
                  Attract the right talent by early
                  adopting innovative technology and being
                  forward-thinking.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12 wow animate__animated animate__fadeInUp">
            <div className="button_sec">
              <a
                href="https://calendly.com/d/2r2-wwr-z6p/immersify-demo"
                className="btn_outline"
                rel="noreferrer"
                target="_blank"
              >
                Book Scoping Session
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* <section className="edu_features">
                <div className="feature_wrap">
                    <div
                        className="feature_blk ftb_1 wow animate__animated animate__bounceIn"
                        data-wow-delay=".2s"
                    >
                        <div className="feature_inn">
                            <h4>Product Features</h4>
                            <ul>
                                <li>3D Animations </li>
                                <li>Gamification </li>
                                <li>Augmented Reality Resources</li>
                                <li>
                                    Scenario-based learning; developing
                                    real-time decision making
                                </li>
                                <li>
                                    Clinical settings; boost confidence and
                                    self-esteem on ‘patient’ exposure
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div
                        className="feature_blk ftb_2 wow animate__animated animate__bounceIn"
                        data-wow-delay="0.5s"
                    >
                        <div className="feature_inn">
                            <h4>Content</h4>
                            <ul>
                                <li>Expert content</li>
                                <li>Publishing partners</li>
                                <li>Partner support</li>
                                <li>Board members; industry expertise </li>
                                <li>Learner feedback; sought-after topics </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="edu_immersify  wow animate__animated animate__zoomInDown">
                    <img src={edu_immersify} alt="Immersify Education" />
                </div>

                <div className="feature_wrap">
                    <div
                        className="feature_blk ftb_3 wow animate__animated animate__bounceIn"
                        data-wow-delay="0.7s"
                    >
                        <div className="feature_inn">
                            <h4>Learner support</h4>
                            <ul>
                                <li>Learn in a safe environment</li>
                                <li>Resources available anytime, anywhere</li>
                                <li>Personalisation</li>
                                <li>
                                    Overcome language barrier; industry-specific
                                    terminology
                                </li>
                                <li>Autonomy over learning </li>
                            </ul>
                        </div>
                    </div>

                    <div
                        className="feature_blk ftb_4 wow animate__animated animate__bounceIn"
                        data-wow-delay="1s"
                    >
                        <div className="feature_inn">
                            <h4>Teaching support</h4>
                            <div className="feat_inside">
                                <h5>Personalised experience</h5>
                                <ul>
                                    <li>Hands off approach</li>
                                </ul>
                            </div>

                            <div className="feat_inside">
                                <h5>Data insights</h5>
                                <ul>
                                    <li>
                                        Areas your learners are struggling with{" "}
                                    </li>
                                </ul>
                            </div>
                            <div className="feat_inside">
                                <h5>Teaching resources</h5>
                                <ul>
                                    <li>3D Animations </li>
                                    <li>Models </li>
                                    <li>Detailed diagrams </li>
                                    <li>Interactive lessons; QR codes</li>
                                    <li>Remote assessment tools </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

    {/*<section className="web_app_block wow animate__animated animate__fadeInUp">*/}
    {/*    <div className="container">*/}
    {/*        <div className="row">*/}
    {/*            <div className="col-md-12">*/}
    {/*                <div className="text-center">*/}
    {/*                    {" "}*/}
    {/*                    <h2 className="landing_main_head">*/}
    {/*                        Explore Immersify<span className="end_not"></span>*/}
    {/*                    </h2>*/}
    {/*                </div>*/}
    {/*                <div className="web_app_in mt-5">*/}
    {/*                    {showIframe ? (*/}
    {/*                        <iframe*/}
    {/*                            src={process.env.REACT_APP_CLIENT_URL + "/Experience/clean"}*/}
    {/*                            height="100%"*/}
    {/*                            width="100%"*/}
    {/*                            title="Immersify Education"*/}
    {/*                            onError={handleIframeError}*/}
    {/*                        ></iframe>*/}
    {/*                    ) : (*/}
    {/*                        <video muted autoPlay>*/}
    {/*                            <source src={explore_immersify} type="video/mp4"></source>*/}
    {/*                        </video>*/}
    {/*                    )}*/}
    {/*                </div>*/}
    {/*            </div>*/}
    {/*            <div className="btn_getintouch mt-5 text-center">*/}
    {/*                <a href={process.env.REACT_APP_CLIENT_URL + "/Experience"} className="btn_common mx-auto">*/}
    {/*                    Explore more*/}
    {/*                </a>*/}
    {/*            </div>*/}
    {/*        </div>*/}
    {/*    </div>*/}
    {/*</section>*/}

    <section className="stats_section edr">
      <div className="container">
        <div className="row">
          <div className="col-lg-12  wow animate__animated animate__fadeInUp">
            <div className="box_head">
              <h2 className="landing_main_head">
                Why educators and learners around the world trust in
                Immersify<span className="end_not"></span>
              </h2>
              <p className="mt-2">Study conducted by Waveform, independent market research company,
                2023.</p>
            </div>
          </div>
          <div className="col-lg-12  wow animate__animated animate__fadeInUp">
            <div className="stats_wrap">
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <div className="stat_block mb-80">
                    <div className="stat_num">
                      <div className="numbers">
                                                    <span className="numbers__window">
                                                        <span
                                                          className={classNames({
                                                            "numbers__window__digit numbers__window__digit--1": true,
                                                            num_anim: addCSS,
                                                          })}
                                                          data-fake="8642519073"
                                                        >
                                                            9
                                                        </span>
                                                    </span>
                        <span className="numbers__window">
                                                        <span
                                                          className={classNames({
                                                            "numbers__window__digit numbers__window__digit--2": true,
                                                            num_anim: addCSS,
                                                          })}
                                                          data-fake="5207186394"
                                                        >
                                                            7
                                                        </span>
                                                    </span>

                        <span className="percnt_ic">%</span>
                      </div>
                    </div>
                    <p> of users agree that the platform has high quality visuals and
                      resources. </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="stat_block mb-80">
                    <div className="stat_num">
                      <div className="numbers">
                                                    <span className="numbers__window">
                                                        <span
                                                          className={classNames({
                                                            "numbers__window__digit numbers__window__digit--1": true,
                                                            num_anim: addCSS,
                                                          })}
                                                          data-fake="8642519073"
                                                        >
                                                            9
                                                        </span>
                                                    </span>
                        <span className="numbers__window">
                                                        <span
                                                          className={classNames({
                                                            "numbers__window__digit numbers__window__digit--1": true,
                                                            num_anim: addCSS,
                                                          })}
                                                          data-fake="5207186394"
                                                        >
                                                            6
                                                        </span>
                                                    </span>

                        <span className="percnt_ic">%</span>
                      </div>
                    </div>
                    <p>say that Immersify makes studying more enjoyable.</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="stat_block mb-80">
                    <div className="stat_num">
                      <div className="numbers">
                                                    <span className="numbers__window">
                                                        <span
                                                          className={classNames({
                                                            "numbers__window__digit numbers__window__digit--1": true,
                                                            num_anim: addCSS,
                                                          })}
                                                          data-fake="8642519073"
                                                        >
                                                            9
                                                        </span>
                                                    </span>
                        <span className="numbers__window">
                                                        <span
                                                          className={classNames({
                                                            "numbers__window__digit numbers__window__digit--1": true,
                                                            num_anim: addCSS,
                                                          })}
                                                          data-fake="5207186394"
                                                        >
                                                            3
                                                        </span>
                                                    </span>

                        <span className="percnt_ic">%</span>
                      </div>
                    </div>
                    <p>of Immersify users think that it’s relevant to them and their studies.</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="stat_block l_child">
                    <div className="stat_num">
                      <div className="numbers">
                                                    <span className="numbers__window">
                                                        <span
                                                          className={classNames({
                                                            "numbers__window__digit numbers__window__digit--1": true,
                                                            num_anim: addCSS,
                                                          })}
                                                          data-fake="8642519073"
                                                        >
                                                            9
                                                        </span>
                                                    </span>
                        <span className="numbers__window">
                                                        <span
                                                          className={classNames({
                                                            "numbers__window__digit numbers__window__digit--2": true,
                                                            num_anim: addCSS,
                                                          })}
                                                          data-fake="5207186394"
                                                        >
                                                            3
                                                        </span>
                                                    </span>

                        <span className="percnt_ic">%</span>
                      </div>
                    </div>
                    <p>find the platform useful to prepare for their exams.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="content_rely wow animate__animated animate__fadeInUp">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading_wrap text-center">
              <h2 className="landing_main_head">
                Content you can rely on<span className="end_not"></span>
              </h2>
              <p className="landing_main_para">
                Our dentistry content is created through collaboration with industry experts and
                dentistry professionals. Meet a
                selection of our experts here:
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-6 col-xl-3 col-12">
            <div className="content_people">
              <div className="card">
                <div className="content">
                  <div className="front">
                    <img src={cr1} alt="Dr. Barry Berkovitz"/>
                    <h4>Dr. Barry Berkovitz</h4>
                    <h6>Expert in Dental Anatomy</h6>
                    <a href="#!" className="btn_readmore d-sm-block d-lg-none mt-3">
                      Read more
                    </a>
                  </div>
                  <div className="back">
                    <p>
                      Dr. Berkovitz qualified at the Royal Dental Hospital of London in 1962. At
                      Royal Holloway College,
                      Kings College London and University of Bristol he taught Anatomy and
                      Dental Histology at the Anatomy
                      Department. He is still involved in academic pursuits and is the author of
                      numerous books and
                      scientific articles.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-3 col-12">
            <div className="content_people">
              <div className="card">
                <div className="content">
                  <div className="front">
                    <img src={cr2} alt="Dr. Barry Berkovitz"/>
                    <h4>Emeritus Bernard Moxham</h4>
                    <h6>Former Professor at Cardiff University</h6>
                    <a href="#!" className="btn_readmore d-sm-block d-lg-none mt-3">
                      Read more
                    </a>
                  </div>
                  <div className="back">
                    <p>
                      After his time as a Professor of Anatomy for 23 years at Cardiff
                      University, he founded the journal
                      Aging Cell, which is the journal for reporting on the biology of ageing
                      that now has the highest
                      scientific impact for this line of research.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-3 col-12">
            <div className="content_people">
              <div className="card">
                <div className="content">
                  <div className="front">
                    <img src={cr3} alt="Dr. Barry Berkovitz"/>
                    <h4>James Darcey</h4>
                    <h6>Consultant at University Hospital Manchester</h6>
                    <a href="#!" className="btn_readmore d-sm-block d-lg-none mt-3">
                      Read more
                    </a>
                  </div>
                  <div className="back">
                    <p>
                      After completing his Masters degree in Oral and Maxillofacial Surgery with
                      distinction from the
                      University of Manchester in 2008. James has also attained Membership in
                      Endodontics from the Royal
                      College of Surgeons of Edinburgh and now holds a consultant post in
                      Restorative Dentistry at the
                      University Dental Hospital of Manchester
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-3 col-12">
            <div className="content_people">
              <div className="card">
                <div className="content">
                  <div className="front">
                    <img src={cr4} alt="Dr. Barry Berkovitz"/>
                    <h4>Jonathan Rees</h4>
                    <h6>Specialist Oral Surgeon</h6>
                    <a href="#!" className="btn_readmore d-sm-block d-lg-none mt-3">
                      Read more
                    </a>
                  </div>
                  <div className="back">
                    <p>
                      As a specialist oral surgeon and general dental surgeon, Jonathan’s
                      experience is dedicated to the
                      placement, restoration and maintenance of dental implants together with
                      the extraction of teeth and
                      surgical management of infection.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="testimonials wow animate__animated animate__fadeInUp">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="testimonial_head">
              <h2>
                What people say about Immersify<span className="end_not"></span>
              </h2>
            </div>
          </div>
          <div className="col-lg-12p-rel">
            <div className="testimonial_sld">
              <Slider {...settings}>
                <div className="testimonial_item">
                  <div>

                    <p>
                      &#34;Educators have the ability to tailor the experience to individual learners&apos; needs,
                      to promote a more effective and inclusive learning environment.
                      Mapping content to standards ensures that learners are not only engaged
                      but also receive education that meets professional guidelines.
                      &#34;
                    </p>

                    <div className="testimonial_person">
                      <div className="testm_img">
                        <img src={amanda_hogan} alt="testimonials"/>
                      </div>
                      <div className="testm_det">
                        <h2>Amanda Hogen</h2>
                        <h5>Clinical Nurse Educator at Endeavor Health, Illinois</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="testimonial_item">
                  <div>

                    <p>
                      &#34;The content is meticulously detailed, academically robust,
                      and designed to provide a comprehensive learning experience.
                      What truly sets Immersify apart is its innovative use of the latest simulation technology,
                      putting practical, hands-on learning literally at your fingertips and making
                      complex concepts more accessible and engaging. &#34;
                    </p>

                    <div className="testimonial_person">
                      <div className="testm_img">
                        <img src={richard_moore} alt="testimonials"/>
                      </div>
                      <div className="testm_det">
                        <h2>Mr Richard Moore </h2>
                        <h5>Consultant Oral Surgeon at University of Leeds</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="testimonial_item">
                  <div>

                    <div>
                      <p>
                        &#34;Witnessing the capabilities of the Immersify platform first-hand really
                        opened my eyes to the ways that augmented reality, 3D visualisations, and
                        gamification can enhance our students&apos; education and really bridge the gap
                        between theory and practice in dentistry. Immersify Education is literally a
                        game changer in education!&#34;
                      </p>
                    </div>

                    <div className="testimonial_person">
                      <div className="testm_img">
                        <img src={kyle_tupaz} alt="testimonials"/>
                      </div>
                      <div className="testm_det">
                        <h2>Kyle Tupaz</h2>
                        <h5>Fund Manager at UCLA School of Dentistry</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="testimonial_item">
                  <div>

                    <p>
                      &#34;Immersify offers an on-the-go augmented reality learning companion,
                      tailored to individual needs, delivering personalized, gamified clinical content in authentic
                      environments,
                      aligning with our education model.&#34;
                    </p>

                    <div className="testimonial_person">
                      <div className="testm_img">
                        <img src={ali_shazib} alt="testimonials"/>
                      </div>
                      <div className="testm_det">
                        <h2>Dr. Ali Shazib</h2>
                        <h5>Dean of High Point University Workman School of Dental Medicine</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="customers wow animate__animated animate__fadeInUp">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="p_head mb-5">A selection of Institutions using Immersify</h2>
            <div className="row">
              <div className="col-md-12">
                <div className="customers_list">
                  <div className="colm">
                    <div className="p_img">
                      <a href="https://www.uhb.nhs.uk" target="_blank" rel="noreferrer">
                        <img src={cs_1} alt="Media image"/>
                      </a>
                    </div>
                  </div>
                  <div className="colm">
                    <div className="p_img">
                      <a href="https://www.cardiff.ac.uk" target="_blank" rel="noreferrer">
                        <img src={cs_2} alt="Media image"/>
                      </a>
                    </div>
                  </div>
                  <div className="colm">
                    <div className="p_img">
                      <a href="https://www.qmul.ac.uk" target="_blank" rel="noreferrer">
                        <img src={cs_3} alt="Media image"/>
                      </a>
                    </div>
                  </div>
                  {/* <div className="colm">
                                        <div className="p_img">
                                            <a
                                                href="https://www.septodont.co.uk"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src={partner3}
                                                    alt="Media image"
                                                />
                                            </a>
                                        </div>
                                    </div> */}

                  <div className="colm">
                    <div className="p_img">
                      <a href="https://www.abdn.ac.uk" target="_blank" rel="noreferrer">
                        <img src={aberdeen} alt="Media image"/>
                      </a>
                    </div>
                  </div>
                  <div className="colm">
                    <div className="p_img">
                      <a href="https://www.ucla.edu/" target="_blank" rel="noreferrer">
                        <img src={ucla} alt="Media image"/>
                      </a>
                    </div>
                  </div>
                  <div className="colm">
                    <div className="p_img">
                      <a href="https://www.jcu.edu.au/" target="_blank" rel="noreferrer">
                        <img src={james_cook} alt="Media image"/>
                      </a>
                    </div>
                  </div>
                  <div className="colm">
                    <div className="p_img">
                      <a href="https://phoenixdentalacademy.co.uk/"
                         target="_blank"
                         rel="noreferrer">
                        <img src={phoenix} alt="Media image"/>
                      </a>
                    </div>
                  </div>
                  <div className="colm">
                    <div className="p_img">
                      <a href="https://www.pnwu.edu/academics/school-of-dental-medicine/"
                         target="_blank"
                         rel="noreferrer">
                        <img src={pnwu} alt="Media image"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 select_uni">
                <p>
                  <button data-bs-toggle="modal"
                          data-bs-target="#getInTouchModal"
                          className="btn_underline">
                    How can my university get Immersify?
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="book_demo">
      <div className="container">
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <div className="demo_txt">
              <h4 className="small_head">See the platform</h4>
              <h2 className="landing_main_head">Want to find out more?</h2>
              <p className="landing_main_para">Enhance your education delivery through advanced
                technology today.</p>
              <div className="button_sec me-auto ms-0 mt-3">
                <a
                  href="https://calendly.com/d/2r2-wwr-z6p/immersify-demo"
                  target="_blank"
                  rel="noreferrer"
                  className="btn_common"
                  onClick={() => firebaseCall("demo_link_clicked", {})}
                >
                  Book a demo
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-6 order-1 order-md-2">
            <div className="demo_img">
              <img src={demo_1} alt="Demo image"/>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="faq">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="box_head">
              <h2 className="landing_main_head">
                Frequently asked questions
                <span className="end_not"></span>
              </h2>
            </div>

            <div className="faq_content">
              <div className="faq_questions">
                <button
                  className="faq_que"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq_1"
                  aria-expanded="false"
                  aria-controls="faq_1"
                >
                  I’m a teacher / lecturer, how can the Immersify Platform support me?{" "}
                  <span className="arrow_change">
                                            <i className="fa fa-angle-right"></i>
                                        </span>
                </button>
                <div className="collapse" id="faq_1">
                  <div className="faq_answ">
                    Utilise our platform’s detailed imagery and visual resources to enhance your
                    teaching or use our gamified
                    elements to make your lessons more engaging. <br/>
                    <br/>
                    {/* <a href="https://immersifyeducation.com/making-the-most-out-of-the-immersify-platform" target="_blank" rel="noreferrer">Check out</a> our ultimate guide for
                                    our educators and how you can make use of our platform.<br /><br /> */}
                    For more information, please contact our education team on{" "}
                    <a href="mailto:info@immersifyeducation.com">info@immersifyeducation.com</a>,
                    and they would be happy to
                    discuss how you can make the most of the platform.
                  </div>
                </div>
              </div>

              <div className="faq_questions">
                <button
                  className="faq_que"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq_2"
                  aria-expanded="false"
                  aria-controls="faq_2"
                >
                  I’m not an educational institution, how do I get our professionals to use the
                  platform?{" "}
                  <span className="arrow_change">
                                            <i className="fa fa-angle-right"></i>
                                        </span>
                </button>
                <div className="collapse" id="faq_2">
                  <div className="faq_answ">
                    Our platform allows individuals to refresh their practical skills in the
                    comfort of their own home.
                    <br/> <br/>
                    You can also screenshare our content, through the Immersify Platform, or share
                    screenshots of diagrams
                    when recapping how to do a procedure.
                    <br/> <br/>
                    {/* For ways to incorporate the Immersify Platform as part of their professional development, <a href="https://immersifyeducation.com/making-the-most-out-of-the-immersify-platform" target="_blank" rel="noreferrer">check out</a> our ultimate guide for
                                    our educators and how you can make use of our platform. */}
                  </div>
                </div>
              </div>

              <div className="faq_questions">
                <button
                  className="faq_que"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq_3"
                  aria-expanded="false"
                  aria-controls="faq_3"
                >
                  Does the Immersify Platform offer any type of qualification?{" "}
                  <span className="arrow_change">
                                            <i className="fa fa-angle-right"></i>
                                        </span>
                </button>
                <div className="collapse" id="faq_3">
                  <div className="faq_answ">
                    Not yet – our goal is to have CPD hours attached to completing modules. But
                    for now our platform is the
                    perfect additional resource to equip learners with the relevant information
                    and reliable content that is
                    needed to progress throughout their career.
                  </div>
                </div>
              </div>

              <div className="faq_questions">
                <button
                  className="faq_que"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq_4"
                  aria-expanded="false"
                  aria-controls="faq_4"
                >
                  How much does the Immersify Platform cost?{" "}
                  <span className="arrow_change">
                                            <i className="fa fa-angle-right"></i>
                                        </span>
                </button>
                <div className="collapse" id="faq_4">
                  <div className="faq_answ">
                    <p>
                      Access all the topics and experiences for free for 14 days with Immersify
                      Premium’s free trial. Learn
                      more about{" "}
                      <button className="btn_readmore" onClick={handleTryWebPlatform}>
                        Premium
                      </button>
                      {" "}
                      here.
                    </p>
                  </div>
                </div>
              </div>

              <div className="faq_questions">
                <button
                  className="faq_que"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq_5"
                  aria-expanded="false"
                  aria-controls="faq_5"
                >
                  How can we, and our learners, access the Immersify Platform?{" "}
                  <span className="arrow_change">
                                            <i className="fa fa-angle-right"></i>
                                        </span>
                </button>
                <div className="collapse" id="faq_5">
                  <div className="faq_answ">
                    You can all access the Immersify Platform on desktop, laptop, tablet or
                    mobile. <br/>
                    <br/>
                    On desktop and laptops, you can access our content through our Immersify
                    Platform above. <br/>
                    <br/>
                    For your hand-held devices, you can check whether your device is compatible by
                    seeing if Immersify appears
                    in the App Store or Google Play Store; usually this is the case for 10+ iOS
                    and 11+ Android. <br/>
                    <br/>
                    Why not check through these links here: <br/>
                    <br/>
                    Immersify -{" "}
                    <a
                      href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DFAQL%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental "
                      target="_blank"
                      rel="noreferrer"
                    >
                      Apple App Store
                    </a>{" "}
                    <br/>
                    Immersify -{" "}
                    <a
                      href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DFAQL%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental "
                      rel="noreferrer"
                      target="_blank"
                    >
                      Google Play Store
                    </a>{" "}
                    <br/>
                    <br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>);
};
